// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
//import { Tooltip, Popover, Modal, Dropdown, Tabs } from "bootstrap"
//import { Offcanvas } from "bootstrap"

import Rails from "@rails/ujs"
//require("@rails/ujs").start()

import {SoundPlayer} from './soundplayer.js'

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")

import "@fortawesome/fontawesome-free/js/all"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// sound player
document.addEventListener("DOMContentLoaded",function(){
	new SoundPlayer()
})


const CookieService = {

    setCookie(name, value, days) {
        let expires = '';

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '')  + expires + ';';
    },

    getCookie(name) {
        const cookies = document.cookie.split(';');

        for (const cookie of cookies) {
            if (cookie.indexOf(name + '=') > -1) {
                return cookie.split('=')[1];
            }
        }

        return null;
    }
}



// exit intent
const mouseEvent = e => {
    const shouldShowExitIntent = 
        !e.toElement && 
        !e.relatedTarget &&
        e.clientY < 10;

    if (shouldShowExitIntent) {
        document.removeEventListener('mouseout', mouseEvent);
        $('#exitIntent').modal('show')
        CookieService.setCookie('exitIntentShown', true, 30);
    }
};

// add leave intent listener after user spent some time on page 
if (!CookieService.getCookie('exitIntentShown')) {
    setTimeout(() => {
		document.addEventListener('mouseout', mouseEvent);
 		}, 5_000)
 }