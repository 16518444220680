import { Howl, Howler } from 'howler'

export class SoundPlayer {
	constructor(prefix) {
		this.playButton = document.getElementById('startStopAudio')
		if(this.playButton == undefined) return

		this.prefix = document.getElementById('audioExamples').dataset.path

    	this.toggleButton = document.getElementById('toggleAudio')
    	this.selectBox = document.getElementById('audioExamples')
    	this.startStopIcon = document.getElementById('startStopIcon')
    	this.loading = document.getElementById('audioLoading')
    	this.isPlaying = false
    	this.isBypassed = true

		this.numSoundsLoaded = 0

	    this.toggleButton.addEventListener('click', ()=>{
			this.toggleByPass()
	    })
    
	    this.playButton.addEventListener('click', ()=>{
	    	this.playStop()
	    })

	    this.selectBox.addEventListener('change', ()=>{
	    	this.loadSelectedSounds()
		})

		this.updatePlayButton()
	}
	
	updatePlayButton() {
		var icon = document.getElementById('startStopIcon')
		if(this.isPlaying) {
			icon.classList.remove('fa-circle-play')
			icon.classList.add('fa-circle-stop')
		}
		else {
			icon.classList.add('fa-circle-play')
			icon.classList.remove('fa-circle-stop')
		}
	}

	playIfAllSoundsLoaded() {
		if(++this.numSoundsLoaded == 2) {
			this.loading.classList.add('d-none')
			if(this.isBypassed) {
				this.soundOn.mute(true)
			}
			else {
				this.soundOff.mute(true)
			}
			this.playButton.removeAttribute('disabled')
			this.playStop()
		}
	}

	loadSelectedSounds() {
		if(this.isPlaying) this.playStop()

		this.playButton.setAttribute('disabled', '')
		
		this.loading.classList.remove('d-none')
		var pathOn  = this.prefix + this.selectBox.value + "_on.wav"
		var pathOff = this.prefix + this.selectBox.value + "_off.wav"

		this.numSoundsLoaded = 0
		this.soundOn  = new Howl({src: [pathOn],
						onload: ()=>{ this.playIfAllSoundsLoaded() } })


		this.soundOff = new Howl({src: [pathOff],
						onload: ()=>{ this.playIfAllSoundsLoaded() } })
	}


	playStop() {
		if(this.numSoundsLoaded == 0) {
    		this.loadSelectedSounds()
    		return
    	}
    	
    	this.isPlaying = !this.isPlaying

    	if(this.isPlaying) {
			this.soundOn.play()
			this.soundOff.play()
    	}
    	else {
			this.soundOn.stop()
			this.soundOff.stop()
    	}
		this.updatePlayButton()
	}

	toggleByPass() {
		this.isBypassed = !this.isBypassed

		if(this.isBypassed) {
			this.toggleButton.classList.remove('btn-primary')
			this.toggleButton.classList.add('btn-secondary')
			if(this.numSoundsLoaded == 2) {
				this.soundOn.mute(true)
				this.soundOff.mute(false)
			}
		}
		else {
			this.toggleButton.classList.remove('btn-secondary')
			this.toggleButton.classList.add('btn-primary')
			if(this.numSoundsLoaded == 2) {
				this.soundOn.mute(false)
				this.soundOff.mute(true)
			}
		}
	}
	
}